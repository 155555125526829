//Header scoroll
$(window).scroll(function() {
    var scroll = $(window).scrollTop();
     //>=, not <=
    if (scroll >= 500) {
        //clearHeader, not clearheader - caps H
        $(".header_wrapper").addClass("pinned");
    }else{
      $(".header_wrapper").removeClass("pinned");
    }
}); //missing );

/*Open Menu*/
$(".open_menu button").on('click', function() {
  if($(this).hasClass('is-active')){
      $("body").css('overflow-y','scroll');
      $(this).removeClass('is-active');
      $('.open_menu button').removeClass('is-active');
      $('.menu').removeClass('openmenu');
    }
    else{
      $(this).addClass('is-active');
      jQuery("body").css('overflow-y','hidden');
      $('.open_menu button').addClass('is-active');
      $('.menu').addClass('openmenu');
      $('ul').removeClass('menu');
  }
});

/*History*/
$(".sidebar p").on('click', function() {
    var p_title=$(this).attr("data-title");
    if($(this).hasClass('active')){

    }else{
      $(".sidebar p").removeClass('active');
      $(this).addClass('active');
    }
    $( "#dates .fullwrapper" ).each(function( index ) {
        div_title=$(this).attr("data-title");
        if(div_title == p_title){
          /*remove style */
          $( "#dates .fullwrapper").removeClass('active');
          $( "#dates .fullwrapper").find('h2').removeClass('aos-init');
          $( "#dates .fullwrapper").find('h2').removeClass('aos-animate');
          $( "#dates .fullwrapper").find('.left').removeClass('aos-init');
          $( "#dates .fullwrapper").find('.left').removeClass('aos-animate');
          /**/
          /*add styles*/
          $(this).find('h2').attr("data-aos","fade-up");
          $(this).find('h2').attr("data-aos-duration","1000");
          $(this).find('h2').addClass('aos-init');
          var find_h2=$(this).find('h2');

          $(this).find('.left').attr("data-aos","custom");
          $(this).find('.left').addClass('aos-init');
          var find_h2=$(this).find('h2');
          var find_left=$(this).find('.left');

          setTimeout(function() {
            find_h2.addClass('aos-animate')
            find_left.addClass('aos-animate');
          }, 500);
          $(this).addClass('active');
          /**/
        }
    });
});

/*Hover Menu - Add Class to all except hover item*/
// $(document).ready(function() {
//   $('#menu-main-menu li a').hover(function() {
//     $('#menu-main-menu li a').not($(this)).addClass('not-hovered');
//   }, function() {
//     $('#menu-main-menu li a').removeClass('not-hovered');
//   });
// });

/*Custom Tabs*/
$("#custom_tabs .tabs p").on('click', function () {
  var tab_open;
  var content_open;
  if($(this).hasClass('active')){

  }else{
    $("#custom_tabs .tabs p").removeClass('active');
    $(this).addClass('active');
    tab_open=$(this).attr("data-tab");
  }
  $( ".tab_content .content" ).each(function( index ) {
      content_open=$(this).attr("data-tab");
      if(content_open == tab_open){
        $( ".tab_content .content" ).removeClass('active');
        $(this).addClass('active');
      }
  });
});


/*Add animation on page load to h1*/
$(document).ready(function () {
  setTimeout(function () {
    $('h1').addClass('is-visible');
  }, 200);
});


/* Default slider swipper */
//import Swiper, { Navigation , Pagination } from 'swiper';

    new Swiper(".gallery_swipe", {
        grabCursor: true,
        speed: 500,
        effect: "cards",
        navigation: {
            nextEl: ".arrows .next",
            prevEl: ".arrows .prev",
        },
    });

/*Language Switcher*/

$(".language.is-active").on('click', function () {
  return false;
});