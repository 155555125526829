import './modules/script';
import './modules/map/mapalex';


import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
AOS.init({
  disable: function() {
    var maxWidth = 767;
    return window.innerWidth < maxWidth;
  }
});
//
// import LocomotiveScroll from 'locomotive-scroll';
//
// const scroll = new LocomotiveScroll({
//     el: document.querySelector('[data-scroll-container]'),
//     smooth: true,
//     getSpeed:true,
//     multiplier:0.7
// });



 // $( document ).ready(function() {
 //
 //   new ResizeObserver(() => scroll.update()).observe(document.querySelector("[data-scroll-container]"));
 //    //sticky header
 //    if( $('body').hasClass('page-template-default')){
 //
 //    }else{
 //      scroll.on('scroll',(instance)=>{
 //              if(instance.scroll.y > 300){
 //                  $('.header_wrapper').addClass('pinned');
 //              }else{
 //                $('.header_wrapper').removeClass('pinned');
 //              }
 //      });
 //    }
 //
 // });

 gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth:1,
  smoothTouch:0.1
});


const img = document.querySelector('#leaf');
let animationId;
img.addEventListener('mousemove', (event) => {
  const imgBox = img.getBoundingClientRect();
  const centerX = imgBox.left + imgBox.width / 2;
  const centerY = imgBox.top + imgBox.height / 2;
  const mouseX = event.clientX - centerX;
  const mouseY = event.clientY - centerY;
  const moveX = mouseX / 50; // Adjust the division value to control the movement speed
  const moveY = mouseY / 50; // Adjust the division value to control the movement speed
  
  img.style.left = `${imgBox.left + moveX}px`;
  img.style.top = `${imgBox.top + moveY}px`;
  
  img.classList.add('move-effect');
  img.style.transition = 'left 0.5s ease-out, top 0.5s ease-out';
  
  img.addEventListener('mouseleave', () => {
    img.style.left = '0';
    img.style.top = '0';
    img.classList.remove('move-effect');
  });
  
  if (animationId) {
    cancelAnimationFrame(animationId);
  }
  animationId = requestAnimationFrame(() => {
    img.classList.remove('move-effect');
  });
});